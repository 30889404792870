import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Lesson from "../../../components/Lesson";
export const meta = {
  published: false,
  publishedAt: "2019-01-16",
  title: "The Future is Server-Side",
  summary: ""
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      